type Predicate<T> = (obj: T) => any | undefined;

export const sortByThenBy = <T>(
  data: T[] = [],
  funcBy: Predicate<T>,
  funcThenBy?: Predicate<T>,
  funcThenBy2nd?: Predicate<T>
) => {
  return [...data].sort((a, b) => {
    const funcByA = funcBy(a);
    const funcByB = funcBy(b);
    const funcThenByA = funcThenBy && funcThenBy(a);
    const funcThenByB = funcThenBy && funcThenBy(b);
    const funcThenBy2ndA = funcThenBy2nd && funcThenBy2nd(a);
    const funcThenBy2ndB = funcThenBy2nd && funcThenBy2nd(b);
    if (funcByA > funcByB) {
      return 1;
    } else if (funcByA < funcByB) {
      return -1;
    } else if (funcThenBy) {
      if (funcThenByA > funcThenByB) return 1;
      else if (funcThenByA < funcThenByB) return -1;
      else if (funcThenBy2nd) {
        if (funcThenBy2ndA > funcThenBy2ndB) return 1;
        else if (funcThenBy2ndA < funcThenBy2ndB) return -1;
        return 0;
      }
    } else {
      return 0;
    }
  });
};

type PredicateExpr<T> = (a: T, b: T) => boolean;

export const removeDuplicates = <T>(data: T[] = [], predicate: PredicateExpr<T>) => {
  const arr = data.filter((value, index, self) => index === self.findIndex(b => predicate(value, b)));
  return arr;
};

export const hasOne = <T>(element: T[]): boolean => {
  return element?.length === 1;
};
export const hasMultiple = <T>(element: T[]): boolean => {
  return element?.length > 1;
};
export const hasOneOrMore = <T>(element: T[]): boolean => {
  return element?.length > 0;
};

export const isEmpty = <T>(element: T[]): boolean => {
  return element?.length === 0;
};

export const moveElement = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};
