import { useScroll } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';

import clsxm from '../../../lib/clsxm';
import { useIsOverflowX } from '../../../utils/useIsOverflow';
import { SideScroll } from '../../atoms/SideScroll';
import { sqbtnSize } from '../../atoms/SquareButton';
import { SideScrollControl } from './SideScrollControl';

interface ISideScrollWithButtonsProps {
  children: ReactNode[];
  item?: ReactNode;
  offset?: number;
  btnSize?: sqbtnSize;
  className?: string;
  centerItem?: boolean;
  childrenAreCentered?: boolean;
  sideScrollClassName?: string;
  sideScrollControlColor?: 'lightgray' | 'gray' | 'white' | 'green';
}

export const SideScrollWithButtons = ({
  children,
  item,
  btnSize,
  offset = 200,
  childrenAreCentered = false,
  sideScrollClassName,
  sideScrollControlColor,
  className
}: ISideScrollWithButtonsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollXProgress } = useScroll({
    container: ref
  });

  const [disabled, setDisabled] = useState({ left: true, right: false });

  const isOverflowX = useIsOverflowX(ref, children);
  const [showSideScrollControl, setShowSideScrollControl] = useState(true);

  useEffect(() => {
    setShowSideScrollControl(isOverflowX);
  }, [isOverflowX]);

  const onScroll = () => {
    setDisabled({
      left: scrollXProgress.get() <= 0,
      right: scrollXProgress.get() >= 1
    });
  };

  useEffect(() => {
    const checkState = scrollXProgress.onChange(onScroll);
    return () => {
      checkState();
    };
  }, []);

  const scrollLeft = () => {
    ref.current.scrollLeft += offset * -1;
  };

  const scrollRight = () => {
    ref.current.scrollLeft += offset;
  };

  return (
    <>
      {(item || !!showSideScrollControl) && (
        <div className={clsxm('flex flex-row mb-6 items-center mr-8 md:mr-16 justify-between w-full pr-8', className)}>
          {item && item}

          {showSideScrollControl && (
            <SideScrollControl
              scrollLeft={scrollLeft}
              scrollRight={scrollRight}
              size={btnSize}
              disabled={disabled}
              color={sideScrollControlColor}
            />
          )}
        </div>
      )}
      <SideScroll areCentered={!isOverflowX ? childrenAreCentered : false} ref={ref} className={sideScrollClassName}>
        {children}
      </SideScroll>
    </>
  );
};
