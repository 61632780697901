import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useBucketList } from '~/src/contexts/bucketListContext';
import { useUserContext } from '~/src/contexts/UserProvider';
import { getLocalizedProperty } from '~/src/lib/localization';
import { toInt } from '~/src/lib/prismaUtils';
import { routes } from '~/src/utils/routes';

import clsxm from '../../lib/clsxm';
import * as gtag from '../../lib/ga4/gtag';
import AhotuImageLoader from '../../loaders/AhotuImageLoader';
import { Size } from '../../types/enums';
import { FeaturedEvent, FeaturedEventsPage } from '../../types/sportPageTypes';
import useMediaQuery from '../../utils/useMediaQuery';
import AhotuLink from '../atoms/AhotuLink';
import AhotwoProperty from '../atoms/AhotwoProperty';
import AhotuButton from '../atoms/form/AhotuButton';
import { Heading } from '../atoms/Heading';
import { Modal } from '../atoms/Modal';
import { Paragraph } from '../atoms/Paragraph';
import { BookmarkIcon, CalendarIcon, HeartIcon, LocationIcon } from '../icons/icons';
import ReviewsHint from '../organisms/reviews/ReviewsHint';

interface IFeaturedCardProps {
  event: FeaturedEvent;
  width?: number;
  isRecommended?: boolean;
  useOn?: FeaturedEventsPage;
}

export const FeaturedCard = ({ event, width, isRecommended = false, useOn }: IFeaturedCardProps) => {
  const { t } = useTranslation();
  const size = isRecommended ? Size.xs : Size.sm;
  const isMobile = useMediaQuery('md'); //FIXME: please stop using these they don't even seem to play nice with ssr

  const { bucketList, addToBucketList, removeFromBucketList } = useBucketList();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { user } = useUserContext();
  const router = useRouter();

  //TODO: edge case - if you click to bookmark a non-bookmarked item, it changes its state eagerly to bookmarked. But since that state is internal to the Icon component, we have no way of forcing it to stay shown. IE: if you quickly move off from the bookmark then it will stop showing - then show again once the request completes.
  const isSelected: boolean = useMemo(
    () => !!bucketList.find(b => toInt(b.a_event_id) === event.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucketList]
  );

  const cardRef = useRef(null);

  useEffect(() => {
    const restoreCalendarRouteAndPosition = JSON.parse(localStorage.getItem('restoreCalendarRouteAndPosition'));
    if (restoreCalendarRouteAndPosition?.eventID === event.id && cardRef.current) {
      setTimeout(() => cardRef.current.scrollIntoView(), 0); //Timeout of 0 needed to add it to some kind of queue, without which the item would not actually be scrolled into view. Frontend makes a lot of sense sometimes.
      localStorage.removeItem('restoreCalendarRouteAndPosition');
    }
  }, [cardRef, event.id]);

  const onClick = () => {
    if (isRecommended) {
      gtag.sendEventSelection(event, 'Recommended events');
      gtag.sendEvent('recommended_for_you', { event_id: event.permalink });
    } else {
      gtag.sendEventSelection(event, 'Featured events');
      gtag.sendEvent(useOn + '_featured_events', { event_id: event.permalink });
    }
  };

  return (
    <>
      {showSignInModal && (
        <Modal
          onClose={() => setShowSignInModal(false)}
          widthClass="max-w-[400px] sm:max-w-[400px]"
          showCloseButton={false}>
          <div className="flex flex-col justify-center gap-y-2 text-center">
            <HeartIcon className="h-16 w-16 mx-auto" />
            <Heading headingLevel="h2" size="xl">
              {t('common:login-modal-login-required')}
            </Heading>
            <Paragraph className="mb-0 ">{t('common:login-modal-event-bucketlist-explanation')}</Paragraph>
            <AhotuButton
              variant="stroke"
              className="mt-4"
              onClick={e => {
                e.preventDefault();
                const url = getLocalizedProperty(routes.members.signIn, router.locale);
                localStorage.setItem('saveEventID', event.id.toString());
                localStorage.setItem(
                  'restoreCalendarRouteAndPosition', //store
                  JSON.stringify({
                    eventID: event.id,
                    path: {
                      pathname: router.pathname,
                      query: {
                        ...router.query
                      }
                    }
                  })
                );
                router.push(url, url);
              }}>
              {t('session:log-in-create-account')}
            </AhotuButton>
          </div>
        </Modal>
      )}
      <AhotuLink href={event.url}>
        <div
          ref={cardRef}
          id={`${isRecommended ? 'recommended' : 'featured'}${event.url}`}
          onClick={onClick}
          className={clsxm(
            isRecommended ? 'text-xs' : 'text-sm',
            'h-fit  snap-center cursor-pointer group/featuredcard relative'
          )}
          style={{ width: width }}>
          <div className={clsxm(isRecommended ? 'h-[119px]' : ' h-[188px]', 'relative')} style={{ width: width }}>
            <Image
              layout="fill"
              objectFit="cover"
              sizes="(max-width: 768px) 50vw, (max-width: 1280px) 33vw, (max-width: 1623px) 25vw, (max-width: 1800px) 20vw, 25vw"
              loader={AhotuImageLoader}
              src={event.titleImage.key}
              placeholder="blur"
              alt={`Image of ${event.name}`}
              blurDataURL={event.titleImage.base64}
            />
            {event.reviewsOverview?.total > 0 && (
              <ReviewsHint
                event={event}
                showTotal={false}
                className="absolute bg-white rounded-full px-[6px] py-1 left-2"
              />
            )}
          </div>
          <div className="h-[100%] flex flex-col justify-around">
            <Heading
              size="sm"
              headingLevel="h3"
              className={clsxm('my-3 font-bold', isMobile ? 'line-clamp-1' : 'h-10 line-clamp-2')}>
              {event.name}
            </Heading>
            <div className="grid gap-y-1">
              <AhotwoProperty size={size} icon={isRecommended && LocationIcon} onOneLine={true}>
                {event.location === 'anywhere' ? t('common:anywhere') : event.location}
              </AhotwoProperty>
              <AhotwoProperty size={size} icon={isRecommended && CalendarIcon} onOneLine={true}>
                {event.date}
              </AhotwoProperty>
              {event.sports && (
                <AhotwoProperty size={size} onOneLine={true}>
                  {event.sports}
                </AhotwoProperty>
              )}
              {event.price && ( //don't show the price until currency microservice is ready
                <AhotwoProperty size={size} className="mt-1">
                  <span dangerouslySetInnerHTML={{ __html: t('common:from-price', { price: event.price }) }}></span>
                </AhotwoProperty>
              )}
            </div>
          </div>
          {
            <div
              className={`absolute right-[8px] top-[0px] bg-white rounded-b-md ${
                isSelected ? '[@media(any-hover:hover)]:opacity-100' : '[@media(any-hover:hover)]:opacity-0'
              } group-hover/featuredcard:opacity-100 transition-opacity duration-150`}>
              <BookmarkIcon
                isSelectedProp={isSelected}
                addEventPromise={() => {
                  if (!user) {
                    return new Promise((_, reject) => {
                      setShowSignInModal(true);
                      reject('Sign-in modal shown');
                    });
                  }
                  return isSelected ? removeFromBucketList(event.id) : addToBucketList(event.id);
                }}
              />
            </div>
          }
        </div>
      </AhotuLink>
    </>
  );
};

export default FeaturedCard;
