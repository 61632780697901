import React, { ReactNode } from 'react';
import { forwardRef } from 'react';

import clsxm from '../../lib/clsxm';

interface ISideScrollProps {
  children: ReactNode[];
  areCentered: boolean;
  className?: string;
}

const Component = (
  { children, areCentered = false, className }: ISideScrollProps,
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  return (
    <div
      className={clsxm(
        'flex flex-row flex-nowrap overflow-x-scroll space-x-6 scrollbar-hide scroll-smooth',
        areCentered && 'justify-center',
        className
      )}
      ref={ref}>
      {children}
    </div>
  );
};

export const SideScroll = forwardRef<HTMLDivElement, ISideScrollProps>(Component);
