import { MutableRefObject, useEffect, useState } from 'react';

export const useIsOverflowY = (ref: MutableRefObject<any>, callback?: (x) => void) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  const triggerOverflowY = () => {
    const hasOverflow = ref?.current?.scrollHeight > ref?.current?.clientHeight;

    setIsOverflow(hasOverflow);

    if (callback) callback(hasOverflow);
  };

  useEffect(() => {
    const { current } = ref;

    if (current) {
      triggerOverflowY();
    }
  }, [callback, ref]);

  useEffect(() => {
    window.addEventListener('resize', triggerOverflowY);

    return () => {
      window.removeEventListener('resize', triggerOverflowY);
    };
  }, []);

  return isOverflow;
};

export const useIsOverflowX = (ref: MutableRefObject<any>, children?: any) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  const triggerOverflowX = () => {
    const hasOverflow = ref?.current?.scrollWidth > ref?.current?.clientWidth;
    setIsOverflow(hasOverflow);
  };

  useEffect(() => {
    triggerOverflowX();
  }, [children]);

  useEffect(() => {
    const { current } = ref;
    if (current) {
      triggerOverflowX();
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', triggerOverflowX);

    return () => {
      window.removeEventListener('resize', triggerOverflowX);
    };
  }, []);

  return isOverflow;
};
