import clsxm from '../../lib/clsxm';
import { Size } from '../../types/enums';
import { Icon } from '../../types/Icon';
interface ISquareButtonProps {
  disabled?: boolean;
  color?: 'lightgray' | 'gray' | 'white' | 'green';
  size?: sqbtnSize;
  onClick: () => void;
  className?: string;
  ariaLabel: string;
  icon: Icon;
}

export type sqbtnSize = Size.sm | Size.lg;

export const SquareButton = ({
  disabled = false,
  color = 'white',
  icon,
  size = Size.lg,
  onClick,
  ariaLabel,
  className
}: ISquareButtonProps) => {
  const handleClick = () => !disabled && onClick();
  const Icon = icon;

  const hasWhiteArrow = (x: boolean) => {
    if (x) {
      return disabled ? '#B3B8BC' : '#FFFFFF';
    } else {
      return disabled ? '#B3B8BC' : '#202228';
    }
  };

  const colorMap = {
    lightgray: {
      background: 'bg-gray-5',
      arrow: hasWhiteArrow(false)
    },

    gray: {
      background: 'bg-gray-20',
      arrow: hasWhiteArrow(false)
    },
    white: {
      background: 'bg-white hover:bg-gray-20',
      arrow: hasWhiteArrow(false)
    },
    green: { background: clsxm(disabled ? 'bg-gray-5' : 'bg-green-70 hover:bg-green-80'), arrow: hasWhiteArrow(true) }
  };

  return (
    <button
      className={clsxm(
        'flex justify-center items-center p-2 cursor-pointer ',
        colorMap[color]['background'],
        disabled && 'cursor-not-allowed hover:gray-5',
        size === Size.lg ? 'h-14 w-14' : 'h-10 w-10',
        className
      )}
      aria-label={ariaLabel}
      onClick={handleClick}>
      <Icon fill={colorMap[color]['arrow']} />
    </button>
  );
};
