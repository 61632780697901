import clsxm from '../../../lib/clsxm';
import { sqbtnSize, SquareButton } from '../../atoms/SquareButton';
import { ArrowLeftAhotuIcon, ArrowRightAhotuIcon } from '../../icons/arrow';

interface ISideScrollControl {
  scrollLeft: () => void;
  scrollRight: () => void;
  size?: sqbtnSize;
  disabled: disabled;
  className?: string;
  color?: 'lightgray' | 'gray' | 'white' | 'green';
}

type disabled = {
  left: boolean;
  right: boolean;
};

export const SideScrollControl = ({
  scrollLeft,
  scrollRight,
  size,
  disabled,
  color,
  className
}: ISideScrollControl) => {
  return (
    <div className={clsxm('hidden space-x-2 md:flex md:flex-row', className)}>
      <SquareButton
        onClick={scrollLeft}
        icon={ArrowLeftAhotuIcon}
        size={size}
        disabled={disabled.left}
        ariaLabel="Scroll left"
        color={color}></SquareButton>
      <SquareButton
        onClick={scrollRight}
        icon={ArrowRightAhotuIcon}
        size={size}
        disabled={disabled.right}
        ariaLabel="Scroll right"
        color={color}></SquareButton>
    </div>
  );
};
