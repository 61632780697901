import { useEffect, useState } from 'react';

/* ***************************************************************
 *                     Read before using                         *
 *                                                               *
 * To use this hook, the parent element has to define a space    *
 * between the Children. Otherwise, the getCardWidth function    *
 * won't work correctly.                                         *
 * Please, also use safe gard to get the first & second id as    *
 * they might not always be multiple elements                    *
 ***************************************************************** */

const getCardWidth = (totalSizeForCards, numberOfCards, gapBetweenCards) => {
  return Math.round((totalSizeForCards - 30 - numberOfCards * gapBetweenCards) / numberOfCards);
};

const usePerfectWidth = (
  firstCardId: string,
  secondCardId: string,
  minWidth: number,
  maxWidth: number,
  defaultWidth: number,
  parentId = ''
) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(undefined);

  const onResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const firstElement = document.getElementById(firstCardId)?.getBoundingClientRect();
    const secondElement = document.getElementById(secondCardId)?.getBoundingClientRect();

    if (!secondElement) {
      setCardWidth(defaultWidth);
      return;
    }
    const leftGapToScreen = firstElement.x;
    let screenWidth = window.innerWidth;
    if (parentId) {
      const margin = screenWidth - document.getElementById(parentId)?.getBoundingClientRect().right;
      screenWidth -= margin;
    }
    const totalSizeForCards = screenWidth - leftGapToScreen;
    const gapBetweenCards = secondElement.x - leftGapToScreen - firstElement.width;

    let finalWidth = 0;
    let numberOfCards = 0;
    while ((finalWidth < minWidth || finalWidth > maxWidth) && numberOfCards < 10) {
      numberOfCards++;
      finalWidth = getCardWidth(totalSizeForCards, numberOfCards, gapBetweenCards);
    }

    if (numberOfCards === 10) {
      setCardWidth(defaultWidth);
    } else {
      setCardWidth(finalWidth);
    }
  }, [windowWidth]);

  if (!secondCardId) return defaultWidth;

  return cardWidth;
};

export default usePerfectWidth;
